<template>
  <div class="h-full flex flex-col px-0 pt-2 pr-2 pb-4">
    <div class="bg-red-400 font-normal text-white px-1 mt-2 rounded-sm rounded-b-none mb-1 justify-between flex">
      <div class="flex items-center">
        <span class="mx-1">DEVICE ERRORS</span>
      </div>
      <div class="flex my-auto ml-1">
        <HashLoader
          :size="20"
          v-show="loading"
          :color="`#FFFFFF`"
        />
      </div>
      <div>
        <button
          class="flex font-normal px-2 mr-1 border border-gray-50 my-2 rounded-sm"
          v-on:click.prevent="switchAutoUpdate()"
        >
          {{
              !enableAutoUpdate ? "ENABLE:AUTO_UPDATE" : "DISABLE:AUTO_UPDATE"
            }}
        </button>
      </div>
    </div>
    <div class="flex-col px-0 md:px-1 py-2 leading-7 flex-1 overflow-y-scroll border border-gray-50 bg-gray-100 rounded-sm rounded-t-none max-h-full">
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-x-1 gap-y-1">
        <DashboardDeviceConfigured
          v-for="device in DEVICES_CONFIGURED"
          v-bind:key="device.id"
          v-bind:device="device"
          class="text-xs"
        ></DashboardDeviceConfigured>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { HashLoader } from "@saeris/vue-spinners";
import DashboardDeviceConfigured from "./components/DashboardDeviceConfigured";
export default {
  name: "Deviceerrors",
  components: { HashLoader, DashboardDeviceConfigured },
  beforeMount() {
    this.USER_OBJECT = JSON.parse(localStorage.getItem("USER_OBJECT"));
    this.$store.commit("UPDATE_USER_OBJECT", this.USER_OBJECT);
    this.AUTH_HEADER = JSON.parse(localStorage.getItem("AUTH_HEADER"));
    this.$store.commit("UPDATE_AUTH_HEADER", this.AUTH_HEADER);
    axios.defaults.headers.common["Authorization"] = this.AUTH_HEADER;
  },
  mounted() {
    this.UpdateDashboard();
  },
  beforeDestroy() {
    clearInterval(this.dashboardUpdater);
  },
  data() {
    return {
      hasError: false,
      enableAutoUpdate: true,
      USER_OBJECT: {},
      AUTH_HEADER: "",
      DEVICES: [],
      DEVICE_INFOS: [],
      loading: false,
    };
  },
  computed: {
    DEVICES_CONFIGURED: function () {
      let LOCAL_DEVICES = this.DEVICES;
      let LOCAL_DEVICE_INFOS = this.DEVICE_INFOS;
      let MERGED = [];
      LOCAL_DEVICES.map((device) => {
        LOCAL_DEVICE_INFOS.map((info) => {
          if (device.playersn == info.playersn) {
            MERGED.push({ ...device, ...info });
          }
        });
      });
      return MERGED.filter(
        (device) =>
          (device.configstate == "CONFIGURED" || device.edited) &&
          (device.undervolted ||
            device.cpu_soft_temp_limited ||
            device.cpu_throttled ||
            device.tempsensorerror)
      ).sort((a, b) =>
        a.last_temperature < b.last_temperature
          ? 1
          : b.last_temperature < a.last_temperature
          ? -1
          : 0
      );
    },
  },
  methods: {
    switchAutoUpdate: function () {
      if (this.enableAutoUpdate) {
        clearInterval(this.dashboardUpdater);
        this.enableAutoUpdate = false;
      } else {
        this.UpdateDashboard();
        this.enableAutoUpdate = true;
      }
    },

    UpdateDashboard: function () {
      let that = this;
      this.GET_DASHBOARD_DEVICES();
      this.dashboardUpdater = setInterval(function () {
        that.GET_DASHBOARD_DEVICES();
      }, 30 * 1000);
    },
    GET_DASHBOARD_DEVICES: function () {
      this.$store.dispatch("GET_DASHBOARD_DEVICES", {}).then(
        (response) => {
          this.DEVICES = response.data;
          this.$store.dispatch("GET_DASHBOARD_DEVICE_INFOSTORE", {}).then(
            (response) => {
              this.DEVICE_INFOS = response.data;
            },
            (error) => {
              if (
                error.response.status >= 400 &&
                error.response.status <= 499
              ) {
                this.hasError = true;
                localStorage.removeItem("USER_OBJECT");
                localStorage.removeItem("AUTH_HEADER");
                this.$store.commit("UPDATE_USER_OBJECT", null);
                this.$store.commit("UPDATE_AUTH_HEADER", null);
                this.$router.push("/");
              }
              if (
                error.response.status >= 500 &&
                error.response.status <= 599
              ) {
                alert("Internal Server Error :(");
              }
            }
          );
        },
        (error) => {
          if (error.response.status >= 400 && error.response.status <= 499) {
            this.loading = false;
            this.hasError = true;
            localStorage.removeItem("USER_OBJECT");
            localStorage.removeItem("AUTH_HEADER");
            this.$store.commit("UPDATE_USER_OBJECT", null);
            this.$store.commit("UPDATE_AUTH_HEADER", null);
            this.$router.push("/");
          } else if (
            error.response.status >= 500 &&
            error.response.status <= 599
          ) {
            alert("Internal Server Error :(");
          }
        }
      );
    },
  },
};
</script>
